$--CTA-button-font-family:"Satoshi", Helvetica;
$--CTA-button-font-size:20px;
$--CTA-button-font-style:normal;
$--CTA-button-font-weight:900;
$--CTA-button-letter-spacing:0px;
$--CTA-button-line-height:normal;
$--ds:0px 8px 12.800000190734863px 0px rgba(0, 0, 0, 0.1);
$--ekita-colours-zimzam-black:rgba(29, 29, 29, 1);
$--ekita-colours-zimzam-pink:rgba(247, 240, 238, 1);
$--h1-font-family:"Chillax", Helvetica;
$--h1-font-size: 82px;
$--h1-font-style: normal;
$--h1-font-weight: 700;
$--h1-highlight-font-family: "Chillax", Helvetica;
$--h1-highlight-font-size: 108px;
$--h1-highlight-font-style: normal;
$--h1-highlight-font-weight: 700;
$--h1-highlight-letter-spacing: 0px;
$--h1-highlight-line-height: 95.50000429153442%;
$--h1-letter-spacing: 0px;
$--h1-line-height: 95.50000429153442%;
$--h2-bold-font-family: "Chillax", Helvetica;
$--h2-bold-font-size: 68px;
$--h2-bold-font-style: normal;
$--h2-bold-font-weight: 700;
$--h2-bold-letter-spacing: 0px;
$--h2-bold-line-height: 114.99999761581421%;
$--h2-font-family: "Chillax", Helvetica;
$--h2-font-size: 68px;
$--h2-font-style: normal;
$--h2-font-weight: 400;
$--h2-letter-spacing: 0px;
$--h2-line-height: 114.99999761581421%;
$--h3-font-family: "Chillax", Helvetica;
$--h3-font-size: 48px;
$--h3-font-style: normal;
$--h3-font-weight: 400;
$--h3-letter-spacing: 0px;
$--h3-line-height: 114.99999761581421%;
$--h4-font-family: "Chillax", Helvetica;
$--h4-font-size: 36px;
$--h4-font-style: normal;
$--h4-font-weight: 400;
$--h4-letter-spacing: 0px;
$--h4-line-height: 114.99999761581421%;
$--h5-font-family: "Chillax", Helvetica;
$--h5-font-size: 28px;
$--h5-font-style: normal;
$--h5-font-weight: 400;
$--h5-letter-spacing: 0px;
$--h5-line-height: 125%;
$--h6-font-family: "Chillax", Helvetica;
$--h6-font-size: 24px;
$--h6-font-style: normal;
$--h6-font-weight: 400;
$--h6-letter-spacing: 0px;
$--h6-line-height: 125%;
$--large-text-font-family: "Satoshi", Helvetica;
$--large-text-font-size: 24px;
$--large-text-font-style: normal;
$--large-text-font-weight: 500;
$--large-text-letter-spacing: 0px;
$--large-text-line-height: 150%;
$--medium-text-font-family: "Satoshi", Helvetica;
$--medium-text-font-size: 20px;
$--medium-text-font-style: normal;
$--medium-text-font-weight: 500;
$--medium-text-letter-spacing: 0px;
$--medium-text-line-height: 150%;
$--paragraph-font-family: "Satoshi", Helvetica;
$--paragraph-font-size: 18px;
$--paragraph-font-style: normal;
$--paragraph-font-weight: 400;
$--paragraph-letter-spacing: 0px;
$--paragraph-line-height: 175%;
$--small-text-font-family: "Satoshi", Helvetica;
$--small-text-font-size: 16px;
$--small-text-font-style: normal;
$--small-text-font-weight: 400;
$--small-text-letter-spacing: 0px;
$--small-text-line-height: 175%;
$--subheading-text-font-family: "Satoshi", Helvetica;
$--subheading-text-font-size: 16px;
$--subheading-text-font-style: normal;
$--subheading-text-font-weight: 500;
$--subheading-text-letter-spacing: 0.64px;
$--subheading-text-line-height: 100%;
$--subtitle-LG-font-family: "Satoshi", Helvetica;
$--subtitle-LG-font-size: 30px;
$--subtitle-LG-font-style: normal;
$--subtitle-LG-font-weight: 500;
$--subtitle-LG-letter-spacing: 1.2px;
$--subtitle-LG-line-height: 100%;
$--variable-collection-white: rgba(255, 255, 255, 1);
$--variable-collection-zimzam-black: var(--ekita-colours-zimzam-black);
$--variable-collection-zimzam-pink: var(--ekita-colours-zimzam-pink);
$--variable-collection-zimzam-purple: rgba(189, 40, 214, 1);

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}